<template>
	<div>
		<pui-datatable 
			:modelName="modelName" ></pui-datatable>
	</div>
</template>

<script>

export default {
	name: 'mterminal-grid',
	data() {
		return {
			modelName: 'mterminal',
		}
	}
}
</script>
